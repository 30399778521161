import React from 'react';

import { graphql } from 'gatsby';

import PageContent from '../components/vsl/variants/delayed/page-content';

export const query = graphql`
  query UngatedVslDelayedPageQuery($pageId: String!, $breakpoints: [Int!]) {
    kalansoPage(pageId: { eq: $pageId }) {
      ...KalansoFullPageFragment
    }
  }
`;

const Template = (props) => {
  return <PageContent {...props} />;
};

export default Template;
